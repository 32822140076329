import React, {useState} from 'react'
import {Link, StaticQuery, graphql, navigate} from 'gatsby'
import styled, {createGlobalStyle} from 'styled-components'

import {addSpace, modularScale, breakpoints} from '../utils/helpers'
import {SearchIcon} from '../styles/styledComps'
import metworx from '../images/metworx.png'
import BlairITCBold from '../fonts/blair-itc-bold.woff2'


const Layout = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: nowrap;
	height: 100%;

	${breakpoints.lg} {
		flex-direction: column;
		margin-top: 2rem;
	}

`

const Font = createGlobalStyle`
	@font-face {
		font-family: 'Blair ITC Bold';
		src: url('${BlairITCBold}') format('woff2');
	}
`


const SearchBox = styled.div`
	background: var(--base-color);
	color: white;
	border-radius: 4px;
	margin: 1rem auto 0;
	max-width: ${modularScale(16)};

	input {
		border: none;
		width: 94%;
		outline: none;

		${breakpoints.sm} {
			width: 91%;
		}
	}
`

const StyledCard = styled(props => <Link {...props} />)`
	width: 350px;
	text-align: center;
	margin: 1rem 2rem;
	border-radius: 4px;
	background: #F5F5F5;
	box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);

	h4 {
		color: var(--base-color);
	}

	&:hover {
		background: var(--base-color);
		h4 {
			color: #FFF;
		}
		text-decoration: none;
	}
`

const Info = styled.div`
	text-align: center;
	margin: 0 0 0 auto;

	${breakpoints.lg} {
		margin: 0;
	}

	img {
		max-width: ${modularScale(16)};
	}

	h1 {
		font-family: 'Blair ITC Bold', san-serif;
		color: var(--base-color);
		margin: 0;

		${breakpoints.sm} {
			font-size: 6vw;
		}
	}
	
	a {
		${breakpoints.lg} {
			margin-top: 1rem;
		}
	}
`

export default IndexPage => (
	<StaticQuery query={graphql`query Cards{ allDirectory(filter: {relativeDirectory: {eq: ""}, relativePath: {ne: "static"}}) { nodes { relativePath } } }`}
	render={data => {
		const[query, setQuery] = useState('')

		return(
			<Layout>
				<Font />
				<Info>
					<img alt='Metworx' src={metworx} />
					<h1>KNOWLEDGEBASE</h1>
					<SearchBox>
						<Link to={'/search'} state={{query: query}}>
							<SearchIcon />
						</Link>
						<input type="text" 
							placeholder="Search" 
							aria-label="Search"
							value={query} 
							onChange={e => setQuery(e.target.value)}
							onKeyDown={e => (e.keyCode === 13) ? navigate('/search', {state: {query: query}}) : (``)}
						/>
					</SearchBox>
				</Info>
				<div style={{display: 'flex', flexDirection: 'column', margin: 'auto'}}>
					{data.allDirectory.nodes.map((kb, i) => (
						<StyledCard to={"/" + kb.relativePath + "/"}>
							<h4 style={{margin: '1rem auto'}}>{addSpace(kb.relativePath)}</h4>
						</StyledCard>
					))}
				</div>
			</Layout>
	)}}/>
)
