import React from 'react'
import styled from 'styled-components'
import { modularScale, breakpoints } from '../utils/helpers'

// Icons
export const SearchIcon = () => (
	<svg style={{
		width: `${modularScale(2)}`, 
		position: 'relative', 
		top: '-2px',
		left: '3px',
		fill: '#FFF',
		padding: '4px',  
	}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 
		44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 
		208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 
		9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 
		0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 
		0 70.7-57.2 128-128 128z"/>
	</svg>
)

// Components
export const Layout = styled.div`
	display: flex;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`

export const Content = styled.div`
	margin: ${modularScale(3)};
	max-width: 1000px;

	${breakpoints.sm} {
		margin: ${modularScale(0)};
	}

	.GitHub {
		fill: var(--base-color);
		width: 1.563rem;
		margin-bottom: 0;
		margin-top: -4px;
		margin-right: 4px;
	}
`

export const Background = styled.div`
	position: fixed; 
	top: 0; 
	right: 0; 
	bottom: 0; 
	left: 0;
	opacity: ${props => props.hide ? 0 : 1};
	visibility: ${props => props.hide ? "hidden" : "visible"};
	background-color: rgba(90,98,112,0.5);
	transition: all 150ms ease-in-out;
	z-index: ${props => props.index};
`